<template>
  <div>
    <el-card>
      <el-form ref="form" :inline="true">
        <el-form-item  label="区域" >
            <el-select
                v-model="queryForm.sanctum_id"
                placeholder="请选择区域"
                clearable
              >
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in sanctumListData"
                  :key="item.key"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item label="地点">
          <el-input
            placeholder="请输入地点"
            v-model="queryForm.place"
          ></el-input>
        </el-form-item>

        <el-form-item label="时间">
          <el-date-picker
            type="datetimerange"
            v-model="times"
            range-separator="至"
            start-placeholder="开始日期"
            end-placeholder="结束日期"
          >
          </el-date-picker>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="toQuery" icon="el-icon-search"
            >查询</el-button
          >
        </el-form-item>
       
        <el-form-item class="generate">
          <el-button type="primary" @click="toAdd"
            >添加地点</el-button
          >
        </el-form-item>
      </el-form>
      <el-table
        :data="sanctumPlaceList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
       
        <el-table-column label="地点" prop="place" align="center"></el-table-column>
        <el-table-column label="地区" prop="sanctum" align="center"></el-table-column>
        <el-table-column label="缩略图" prop="thumb_url" align="center">
          <template slot-scope="scope">
            <div class="thumb_box">
              <img :src="scope.row.thumb_url" alt="" />
            </div>
          </template>
        </el-table-column>
       
        <el-table-column label="操作" align="center" >
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editGoods(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeTask(scope.row.id)"
              size="small"
            ></el-button>
            <el-tooltip class="item" effect="dark" content="对话列表" placement="top">
              <el-button
                type="primary"
                icon="el-icon-tickets"
                circle
                @click="openSanctumTalk(scope.row.id)"
                size="small"
              ></el-button>
            </el-tooltip>
            <el-tooltip class="item" effect="dark" content="知识点列表" placement="bottom">
              <el-button
                type="primary"
                icon="el-icon-tickets"
                circle
                @click="openSanctumLore(scope.row.id)"
                size="small"
              ></el-button>
            </el-tooltip>
          </template>
        </el-table-column>
      </el-table>

       <!-- 分页区域 -->
     <div class="pagination">
       <span></span>
        <el-pagination
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
        :current-page="queryForm.page"
        :page-sizes="[3, 5, 8, 10]"
        :page-size="queryForm.limit"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        algin:="center"
      ></el-pagination>
     </div>

    <!-- 添加地点弹框 -->
    <el-dialog :title="addtitle" :visible.sync="addDialog" :center=true width="60%" @close="closed" :close-on-click-modal=false>
      <el-form :model="addForm"  ref="addForm">
        <el-form-item el-form-item label="区域" label-width="100px">
            <el-select
                v-model="addForm.sanctum_id"
                placeholder="请选择区域"
                clearable
              >
                <el-option
                  :label="item.value"
                  :value="item.key"
                  v-for="item in sanctumListData"
                  :key="item.key"
                ></el-option>
            </el-select>
        </el-form-item>
        <el-form-item el-form-item label="地点名" label-width="100px">
            <el-input
              type="text"
              v-model="addForm.place"
              placeholder="请输入地点名"
            />
        </el-form-item>

        <el-form-item el-form-item label="缩略图" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeThumbUpload"
            >
              <img
                v-if="thumbSrc"
                style="width: 100%"
                :src="thumbSrc"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px"
              ></i>
            </el-upload>
          </el-form-item>
           <el-form-item el-form-item label="原文：" label-width="100px">
           <el-input v-model="addForm.original" placeholder="请输入原文"></el-input>
        </el-form-item>

        <el-form-item el-form-item label="翻译：" label-width="100px">
           <el-input v-model="addForm.chinese" placeholder="请输入翻译"></el-input>
        </el-form-item>


        <el-form-item el-form-item label="背景音频" label-width="100px">
          <div v-if="addForm.audio_url">
              <audio
                :src="addForm.audio_url"
                style="cursor: pointer;outline: none;"
                controls="controls" 
                ref="audioPlay"
              ></audio>
          </div>
          <el-button type="primary" @click="selectAudio"
            >{{isAdd=='add'?'上传背景音频':'修改背景音频'}}</el-button
          ><span class="file-name">{{ fileAudioName }}</span>
          <el-progress :percentage="progress" v-if="progress"></el-progress>
          <input
            type="file"
            name="audio"
            id=""
            hidden
            ref="audio"
            @change="selectAudioFiles"
            accept="audio/*"
          />
        </el-form-item>

        <el-form-item el-form-item label="谈话音频" label-width="100px">
          <div v-if="addForm.talk_url">
              <audio
                :src="addForm.talk_url"
                style="cursor: pointer;outline: none;"
                controls="controls" 
                ref="talkPlay"
              ></audio>
          </div>
          <el-button type="primary" @click="talkAudio"
            >{{isAdd=='add'?'上传谈话音频':'修改谈话音频'}}</el-button
          ><span class="file-name">{{ talkName }}</span>
          <el-progress :percentage="talkProgress" v-if="talkProgress"></el-progress>
          <input
            type="file"
            hidden
            ref="talk_audio"
            @change="talkAudioFiles"
            accept="audio/*"
          />
        </el-form-item>

        <el-form-item el-form-item label="地点背景" label-width="100px">
            <el-upload
              class="avatar-uploader"
              action="123"
              :show-file-list="false"
              accept="image/*"
              :before-upload="beforeBackdropThumbUpload"
            >
              <img
                v-if="backdropThumbSrc"
                style="width: 100%;"
                :src="backdropThumbSrc"
                class="avatar"
              />
              <i
                v-else
                class="el-icon-plus avatar-uploader-icon"
                style="margin-top: 85px"
              ></i>
            </el-upload>
        </el-form-item>

        <el-form-item el-form-item label="角色图" label-width="100px">
            <el-row style="display:flex">
              <el-upload
                class="avatar-uploader"
                action="123"
                :show-file-list="false"
                accept="image/*"
                :before-upload="beforeCharacterThumbUpload"
              >
                <img
                  v-if="characterThumbSrc"
                  style="width: 100px;height:180px"
                  :src="characterThumbSrc"
                  class="avatar"
                />
                <i
                  v-else
                  class="el-icon-plus avatar-uploader-icon"
                  style="margin-top: 85px"
                ></i>
              </el-upload>
            </el-row>
            <el-row>
              <div class="tips">上传角色缩略图尺寸为400 X 1080且不大于10MB</div>
            </el-row>
        </el-form-item>

        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAdd=='add'" @click="addNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editReadNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 圣地巡礼知识点列表 -->
    <el-dialog title="圣地巡礼知识点列表" :visible.sync="sanctumLoreListDialog" :center=true width="50%" :close-on-click-modal=false>
      <div class="title_box">
          <div></div>
          <div class="hearingLibDialog_title">
               <el-button type="primary" @click="toAddSanctumLore">添加知识点</el-button>
          </div>
      </div>

      <el-table
        :data="sanctumLoreList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="知识点" prop="lore" align="center"></el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
        
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editSanctumLore(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeSanctumLore(scope.row.id)"
              size="small"
            ></el-button> 
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 添加圣地巡礼知识点-->
    <el-dialog :title="addSanctumLoreTitle" :visible.sync="addSanctumLoreDialog" :center=true width="40%" @close="sanctumLoreClose" :close-on-click-modal=false>
      <el-form :model="addSanctumLoreData"  ref="addAuxiliaryVideoForm">
        <el-form-item el-form-item label="知识点：" label-width="100px">
           <el-input v-model="addSanctumLoreData.lore" placeholder="请输入知识点"></el-input>
        </el-form-item>

        <el-form-item  el-form-item label="知识内容：" label-width="100px">
            <quill-editor
              v-model="addSanctumLoreData.content"
            >
            </quill-editor>
        </el-form-item>
       
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAddSanctumLore=='add'" @click="addSanctumLoreNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editSanctumLoreNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 日语APP圣地巡礼对话列表 -->
    <el-dialog title="圣地巡礼对话列表" :visible.sync="sanctumTalkListDialog" :center=true width="70%" :close-on-click-modal=false>
      <div class="title_box">
          <div></div>
          <div class="hearingLibDialog_title">
               <el-button type="primary" @click="toAddsanctumTalk">添加对话</el-button>
          </div>
      </div>

      <el-table
        :data="sanctumTalkList"
        stripe
        class="table"
        ref="userTable.multipleSelection"
        header-cell-class-name="table-header"
        border
      >
        <el-table-column
          type="selection"
          width="55"
          align="center"
        ></el-table-column>

        <el-table-column
          prop="id"
          label="ID"
          width="55"
          align="center"
        ></el-table-column>
        <el-table-column label="角色名" prop="name" align="center"></el-table-column>
        <el-table-column label="原文" prop="original" align="center"></el-table-column>
        <el-table-column label="翻译" prop="chinese" align="center"></el-table-column>
        <el-table-column label="时间" align="center">
          <template slot-scope="scope">
            {{
                $moment(scope.row.create_time * 1000).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
              }}
          </template>
        </el-table-column>
        
        <el-table-column label="操作" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              type="primary"
              icon="el-icon-edit"
              circle
              @click="editSanctumTalk(scope.row)"
              size="small"
            ></el-button>
            <el-button
              type="danger"
              icon="el-icon-delete"
              circle
              @click="removeSanctumTalk(scope.row.id)"
              size="small"
            ></el-button> 
          </template>
        </el-table-column>
      </el-table>
    </el-dialog>

    <!-- 添加日语APP圣地巡礼对话-->
    <el-dialog :title="addSanctumTalkTitle" :visible.sync="addSanctumTalkDialog" :center=true width="60%" @close="addSanctumTalkClose" :close-on-click-modal=false>
      <el-form :model="addSanctumTalkData"  ref="addSanctumTalkForm">
        
        <el-form-item el-form-item label="角色位置" label-width="100px">
            <el-select
              v-model="addSanctumTalkData.role"
              placeholder="请选择角色位置"
              clearable
            >
              <el-option
                :label="item.value"
                :value="item.key"
                v-for="item in roleArr"
                :key="item.key"
              ></el-option>
            </el-select>
        </el-form-item>

        <el-form-item el-form-item label="角色名字：" label-width="100px">
           <el-input v-model="addSanctumTalkData.name" placeholder="请输入名字"></el-input>
        </el-form-item>

        <el-form-item el-form-item label="原文：" label-width="100px">
           <el-input v-model="addSanctumTalkData.original" placeholder="请输入原文"></el-input>
        </el-form-item>

        <el-form-item el-form-item label="翻译：" label-width="100px">
           <el-input v-model="addSanctumTalkData.chinese" placeholder="请输入翻译"></el-input>
        </el-form-item>

        <el-form-item el-form-item label="男音" label-width="100px">
          <div v-if="addSanctumTalkData.male_voice_url">
                <audio
                  :src="addSanctumTalkData.male_voice_url"
                  style="cursor: pointer;outline: none;"
                  controls="controls" 
                  ref="male_voicePlay"
                ></audio>
          </div>
          <el-button type="primary" @click="male_voiceAudio"
            >{{isAddSanctumTalk=='add'?'上传男音':'修改男音'}}</el-button
          ><span class="file-name">{{ male_voiceNmme }}</span>
          <el-progress :percentage="male_voiceProgress" v-if="male_voiceProgress"></el-progress>
          <input
            type="file"
            hidden
            ref="male_voice_audio"
            @change="male_voiceAudioFiles"
            accept="audio/*"
          />
        </el-form-item>

        <el-form-item el-form-item label="女音" label-width="100px">
          <div v-if="addSanctumTalkData.male_voice_url">
              <audio
                :src="addSanctumTalkData.male_voice_url"
                style="cursor: pointer;outline: none;"
                controls="controls" 
                ref="female_voicePlay"
              ></audio>
          </div>
          <el-button type="primary" @click="female_voiceAudio"
            >{{isAddSanctumTalk=='add'?'上传女音':'修改女音'}}</el-button
          ><span class="file-name">{{ female_voiceName }}</span>
          <el-progress :percentage="female_voiceProgress" v-if="female_voiceProgress"></el-progress>
          <input
            type="file"
            hidden
            ref="female_voice_audio"
            @change="female_voiceAudioFiles"
            accept="audio/*"
          />
        </el-form-item>

        <el-form-item el-form-item label="肖像缩略图：" label-width="100px">
            <el-row>
              <el-upload
                  class="avatar-uploader"
                  action="123"
                  :show-file-list="false"
                  accept="image/*"
                  :before-upload="beforePortraitThumbUpload"
                >
                  <img
                    v-if="portraitThumbSrc"
                    style="width: 100%"
                    :src="portraitThumbSrc"
                    class="avatar"
                  />
                  <i
                    v-else
                    class="el-icon-plus avatar-uploader-icon"
                    style="margin-top: 85px"
                ></i>
              </el-upload>
            </el-row>
            <el-row>
              <div class="tips">上传肖像缩略图尺寸为160 X 210且不大于10MB</div>
            </el-row>
        </el-form-item>
 
        <el-form-item label-width=40% style="margin-top: 20px;">
           <el-button type="primary" v-if="isAddSanctumTalk=='add'" @click="addSanctumTalkNow" >立即添加</el-button>
           <el-button type="primary" v-else @click="editSanctumTalkNow">立即修改</el-button>
        </el-form-item>
      </el-form>
    </el-dialog>

    </el-card>
  </div>
</template>

<script>
//引入富文本组件
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";
var COS = require("cos-js-sdk-v5");

import {
  SanctumPlaceList,
  getSanctumCosToken,
  SanctumPlaceAdd,
  SanctumPlaceEdit,
  SanctumPlaceDel,
  SanctumList,
  SanctumEdit,
  SanctumLoreList,
  SanctumLoreAdd,
  SanctumLoreEdit,
  SanctumLoreDel,
  SanctumTalkList,
  SanctumTalkAdd,
  SanctumTalkEdit,
  SanctumTalkDel,
} from "@/api/Japanese_app/sanctum_place.js";
export default {
  components: {
    quillEditor,
  },
  data() {
    return {
      queryForm: {
        sanctum_id: "",
        place: "",
        page: 1,
        limit: 10,
        start_time: null,
        end_time: null,
      },
      sanctumListData: [],
      sanctumPlaceList: [],
      total: 0,
      addDialog: false,
      times: null,
      addForm: {
        sanctum_id: "",
        place: "",
        thumb: "",
        audio: "",
        backdrop: "",
        character: "",
        original: "",
        chinese: "",
        talk: "",
      },
      addtitle: "",
      isAdd: "add",
      thumbSrc: "",
      talkName: "",
      talkProgress: 0,
      backdropThumbSrc: "",
      characterThumbSrc: "",
      fileAudioName: "",
      progress: 0,
      sanctumLoreListDialog: false,
      sanctumLoreList: [],
      addSanctumLoreTitle: "",
      isAddSanctumLore: "",
      addSanctumLoreDialog: false,
      sanctumLoreId: null,
      addSanctumLoreData: {
        place_id: "",
        lore: "",
        content: "",
      },
      sanctumTalkListDialog: false,
      addSanctumTalkTitle: "",
      isAddSanctumTalk: "",
      addSanctumTalkDialog: false,
      sanctumTalkList: [],
      sanctumTalkId: null,
      addSanctumTalkData: {
        place_id: "",
        role: "",
        name: "",
        original: "",
        chinese: "",
        male_voice: "",
        female_voice: "",
        portrait: ""
      },
      male_voiceNmme: "",
      female_voiceName: "",
      male_voiceProgress: 0,
      female_voiceProgress: 0,
      portraitThumbSrc: "",
      roleArr: [
        { key: "左", title: "左" },
        { key: "右", title: "右" },
      ],
    };
  },

  created() {
    this.getSanctumList();
    this.getSanctumPlaceList();
  },
  methods: {
    //   日语APP圣地巡礼地点列表
    getSanctumPlaceList() {
      SanctumPlaceList(this.queryForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.total = res.data.total;
        this.sanctumPlaceList = res.data.data;
        // console.log(this.sanctumPlaceList);
      });
    },

    // 删除地点
    async removeTask(id) {
      const result = await this.$confirm("是否要删除该地点?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      SanctumPlaceDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除地点成功");
        this.getSanctumPlaceList();
      });
    },

    // 添加弹窗
    toAdd() {
      this.isAdd = "add";
      this.addtitle = "添加地点";
      this.addDialog = true;
    },

    // 上传缩略图
    beforeThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getSanctumCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.thumb = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.thumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },
    // 点击选择音频文件按钮
    selectAudio() {
      this.$refs.audio.click();
    },

    // 选中音频
    selectAudioFiles(e) {
      let file = this.$refs.audio.files[0];
      this.fileAudioName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getSanctumCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.progress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addForm.audio = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 点击选择谈话音频文件按钮
    talkAudio() {
      this.$refs.talk_audio.click();
    },

    // 选中谈话音频
    talkAudioFiles(e) {
      let file = this.$refs.talk_audio.files[0];
      this.talkName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getSanctumCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.talkProgress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addForm.talk = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 上传地点背景
    beforeBackdropThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传区域地点背景图不能超过 10MB!");
        return;
      }
      let that = this;
      getSanctumCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addForm.backdrop = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.backdropThumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 上传角色图
    beforeCharacterThumbUpload(file) {
      let that = this;
      const is1M = file.size / 1024 / 1024 < 10; // 限制小于1M
      const isSize = new Promise(function (resolve, reject) {
        let width = 400; // 限制图片尺寸为654X270
        let height = 1080;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          getSanctumCosToken({ suffix: file.name }).then((res) => {
            var cos = new COS({
              getAuthorization: function (options, callback) {
                callback({
                  TmpSecretId: res.data.credentials.tmpSecretId,
                  TmpSecretKey: res.data.credentials.tmpSecretKey,
                  XCosSecurityToken: res.data.credentials.sessionToken,
                  StartTime: res.data.startTime,
                  ExpiredTime: res.data.expiredTime,
                  expiration: res.data.expiration,
                  requestId: res.data.requestId,
                });
              },
            });
            cos.putObject(
              {
                Bucket: res.data.Bucket,
                Region: res.data.Region,
                Key: res.data.key,
                Body: file, // 上传文件对象
                onProgress: function (progressData) {},
              },
              function (err, data) {
                if (data) {
                  that.addForm.character = res.data.key;
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (e) => {
                    that.characterThumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                    that.$forceUpdate();
                  };
                }
              }
            );
          });
          return file;
        },
        () => {
          that.$message.error(
            "上传角色缩略图尺寸限制为400 x 1080，大小不可超过10MB"
          );
          // return Promise.reject();
        }
      );
    },

    // 添加地点
    addNow() {
      if (this.addForm.sanctum_id == "") {
        this.$message.error("请选择区域");
        return;
      } else if (this.addForm.place == "") {
        this.$message.error("请输入地点名");
        return;
      } else if (this.addForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (this.addForm.original == "") {
        this.$message.error("请输入原文");
        return;
      } else if (this.addForm.chinese == "") {
        this.$message.error("请输入翻译");
        return;
      } else if (this.addForm.audio == "") {
        this.$message.error("请上传音频");
        return;
      } else if (this.addForm.talk == "") {
        this.$message.error("请上传对话音频");
        return;
      } else if (this.addForm.backdrop == "") {
        this.$message.error("请上传地点背景图");
        return;
      } else if (this.addForm.character == "") {
        this.$message.error("请上传角色图");
        return;
      }
      SanctumPlaceAdd(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加区域成功");
        this.addDialog = false;
        this.getSanctumPlaceList();
      });
    },

    // 点击编辑图标
    editGoods(item) {
      this.isAdd = "edit";
      this.addtitle = "编辑地点";
      this.addDialog = true;
      this.addForm = JSON.parse(JSON.stringify(item));
      this.thumbSrc = this.addForm.thumb_url;
      this.backdropThumbSrc = this.addForm.backdrop_url;
      this.characterThumbSrc = this.addForm.character_url;
    },

    // 点击立即修改
    editReadNow() {
      if (this.addForm.sanctum_id == "") {
        this.$message.error("请选择区域");
        return;
      } else if (this.addForm.place == "") {
        this.$message.error("请输入地点名");
        return;
      } else if (this.addForm.thumb == "") {
        this.$message.error("请上传缩略图");
        return;
      } else if (this.addForm.original == "") {
        this.$message.error("请输入原文");
        return;
      } else if (this.addForm.chinese == "") {
        this.$message.error("请输入翻译");
        return;
      } else if (this.addForm.audio == "") {
        this.$message.error("请上传音频");
        return;
      } else if (this.addForm.talk == "") {
        this.$message.error("请上传对话音频");
        return;
      } else if (this.addForm.backdrop == "") {
        this.$message.error("请上传地点背景图");
        return;
      } else if (this.addForm.character == "") {
        this.$message.error("请上传角色图");
        return;
      }
      SanctumPlaceEdit(this.addForm).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改区域成功");
        this.addDialog = false;
        this.getSanctumPlaceList();
      });
    },

    // 弹窗关闭事件
    closed() {
      this.addForm = {
        sanctum_id: "",
        place: "",
        thumb: "",
        audio: "",
        backdrop: "",
        character: "",
        original: "",
        chinese: "",
        talk: "",
      };
      this.thumbSrc = "";
      this.talkName = "";
      this.talkProgress = 0;
      this.backdropThumbSrc = "";
      this.characterThumbSrc = "";
      this.fileAudioName = "";
      this.progress = 0;
      if (this.$refs.audioPlay !== undefined) {
        this.$refs.audioPlay.pause();
      }

      if (this.$refs.talkPlay !== undefined) {
        this.$refs.talkPlay.pause();
      }
    },

    // 日语APP圣地巡礼区域列表
    getSanctumList() {
      SanctumList().then((res) => {
        this.sanctumListData = res.data;
      });
    },

    // 上传区域缩略图
    beforeSanctumScriptThumbUpload(file) {
      const isSize = file.size / 1024 / 1024 < 10;
      if (!isSize) {
        this.$message.error("上传缩略图不能超过 10MB!");
        return;
      }
      let that = this;
      getSanctumCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {},
          },
          function (err, data) {
            if (data) {
              that.addSanctumScriptData.thumb = res.data.key;
              const reader = new FileReader();
              reader.readAsDataURL(file);
              reader.onload = (e) => {
                that.SanctumScriptThumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                that.$forceUpdate();
              };
            }
          }
        );
      });
    },

    // 打开日语APP圣地巡礼知识点列表
    openSanctumLore(id) {
      this.sanctumLoreId = id;
      this.getSanctumLoreList(id);
      this.sanctumLoreListDialog = true;
    },

    // 获取圣地巡礼知识点列表
    getSanctumLoreList(id) {
      SanctumLoreList({ place_id: id }).then((res) => {
        this.sanctumLoreList = res.data;
      });
    },

    // 添加日语APP圣地巡礼知识点
    toAddSanctumLore() {
      this.addSanctumLoreTitle = "添加日语APP圣地巡礼知识点";
      this.isAddSanctumLore = "add";
      this.addSanctumLoreDialog = true;
    },

    // 点击立即添加圣地巡礼知识点
    addSanctumLoreNow() {
      this.addSanctumLoreData.place_id = this.sanctumLoreId;
      if (this.addSanctumLoreData.lore == "") {
        this.$message.error("请输入知识点");
        return;
      } else if (this.addSanctumLoreData.content == "") {
        this.$message.error("请请输入知识点内容");
        return;
      }
      SanctumLoreAdd(this.addSanctumLoreData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加知识点成功");
        this.addSanctumLoreDialog = false;
        this.getSanctumLoreList(this.sanctumLoreId);
      });
    },

    // 点击修改圣地巡礼知识点
    editSanctumLore(item) {
      this.addSanctumLoreTitle = "编辑日语APP圣地巡礼知识点";
      this.isAddSanctumLore = "edit";
      this.addSanctumLoreDialog = true;
      this.addSanctumLoreData = JSON.parse(JSON.stringify(item));
    },

    // 点击立即圣地巡礼知识点
    editSanctumLoreNow() {
      this.addSanctumLoreData.place_id = this.sanctumLoreId;
      if (this.addSanctumLoreData.lore == "") {
        this.$message.error("请输入知识点");
        return;
      } else if (this.addSanctumLoreData.content == "") {
        this.$message.error("请请输入知识点内容");
        return;
      }
      SanctumLoreEdit(this.addSanctumLoreData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改知识点成功");
        this.addSanctumLoreDialog = false;
        this.getSanctumLoreList(this.sanctumLoreId);
      });
    },

    // 点击删除圣地巡礼知识点
    async removeSanctumLore(id) {
      const result = await this.$confirm("是否要删除该知识点?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      SanctumLoreDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除知识点成功");
        this.getSanctumLoreList(this.sanctumLoreId);
      });
    },

    // 添加圣地巡礼知识点弹框关闭事件
    sanctumLoreClose() {
      this.addSanctumLoreData = {
        place_id: "",
        lore: "",
        content: "",
      };
    },

    // 打开日语APP圣地巡礼对话列表
    openSanctumTalk(id) {
      this.sanctumTalkId = id;
      this.getSanctumTalkList(id);
      this.sanctumTalkListDialog = true;
    },

    // 获取日语APP圣地巡礼对话列表
    getSanctumTalkList(id) {
      SanctumTalkList({ place_id: id }).then((res) => {
        this.sanctumTalkList = res.data;
      });
    },

    // 添加日语APP圣地巡礼对话
    toAddsanctumTalk() {
      this.addSanctumTalkTitle = "添加日语APP圣地巡礼对话";
      this.isAddSanctumTalk = "add";
      this.addSanctumTalkDialog = true;
    },

    // 点击上传男音按钮
    male_voiceAudio() {
      this.$refs.male_voice_audio.click();
    },

    // 选中男音音频
    male_voiceAudioFiles(e) {
      let file = this.$refs.male_voice_audio.files[0];
      this.male_voiceNmme = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getSanctumCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.male_voiceProgress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addSanctumTalkData.male_voice = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 点击上传女音按钮
    female_voiceAudio() {
      this.$refs.female_voice_audio.click();
    },

    // 选中女音音频
    female_voiceAudioFiles(e) {
      let file = this.$refs.female_voice_audio.files[0];
      this.female_voiceName = file.name;
      const isSize = file.size / 1024 / 1024 < 30;
      if (!isSize) {
        this.$message.error("上传音频不能超过 30MB!");
        return;
      }

      let that = this;
      getSanctumCosToken({ suffix: file.name }).then((res) => {
        var cos = new COS({
          getAuthorization: function (options, callback) {
            callback({
              TmpSecretId: res.data.credentials.tmpSecretId,
              TmpSecretKey: res.data.credentials.tmpSecretKey,
              XCosSecurityToken: res.data.credentials.sessionToken,
              StartTime: res.data.startTime,
              ExpiredTime: res.data.expiredTime,
              expiration: res.data.expiration,
              requestId: res.data.requestId,
            });
          },
        });
        cos.putObject(
          {
            Bucket: res.data.Bucket,
            Region: res.data.Region,
            Key: res.data.key,
            // ContentType:'multipart/form-data',
            Body: file, // 上传文件对象
            onProgress: function (progressData) {
              that.female_voiceProgress = progressData.percent * 100;
            },
          },
          function (err, data) {
            if (data) {
              that.addSanctumTalkData.female_voice = res.data.key;
            }
            e.target.value = "";
          }
        );
      });
    },

    // 上传肖像缩略图
    beforePortraitThumbUpload(file) {
      let that = this;
      const is1M = file.size / 1024 / 1024 < 10; // 限制小于1M
      const isSize = new Promise(function (resolve, reject) {
        let width = 160; // 限制图片尺寸为654X270
        let height = 210;
        let _URL = window.URL || window.webkitURL;
        let img = new Image();
        img.onload = function () {
          let valid = img.width === width && img.height === height;
          valid ? resolve() : reject();
        };
        img.src = _URL.createObjectURL(file);
      }).then(
        () => {
          getSanctumCosToken({ suffix: file.name }).then((res) => {
            var cos = new COS({
              getAuthorization: function (options, callback) {
                callback({
                  TmpSecretId: res.data.credentials.tmpSecretId,
                  TmpSecretKey: res.data.credentials.tmpSecretKey,
                  XCosSecurityToken: res.data.credentials.sessionToken,
                  StartTime: res.data.startTime,
                  ExpiredTime: res.data.expiredTime,
                  expiration: res.data.expiration,
                  requestId: res.data.requestId,
                });
              },
            });
            cos.putObject(
              {
                Bucket: res.data.Bucket,
                Region: res.data.Region,
                Key: res.data.key,
                Body: file, // 上传文件对象
                onProgress: function (progressData) {},
              },
              function (err, data) {
                if (data) {
                  that.addSanctumTalkData.portrait = res.data.key;
                  const reader = new FileReader();
                  reader.readAsDataURL(file);
                  reader.onload = (e) => {
                    that.portraitThumbSrc = e.target["result"]; // 获取文件装换后的Base64位文件
                    that.$forceUpdate();
                  };
                }
              }
            );
          });
          return file;
        },
        () => {
          that.$message.error(
            "上传肖像缩略图尺寸限制为160 x 210，大小不可超过10MB"
          );
        }
      );
    },

    // 点击立即添加圣地巡礼对话
    addSanctumTalkNow() {
      this.addSanctumTalkData.place_id = this.sanctumTalkId;
      if (this.addSanctumTalkData.role == "") {
        this.$message.error("请选择角色位置");
        return;
      } else if (this.addSanctumTalkData.name == "") {
        this.$message.error("请输入角色名字");
        return;
      } else if (this.addSanctumTalkData.original == "") {
        this.$message.error("请输入原文");
        return;
      } else if (this.addSanctumTalkData.chinese == "") {
        this.$message.error("请输入翻译");
        return;
      } else if (
        this.addSanctumTalkData.male_voice == "" &&
        this.addSanctumTalkData.female_voice == ""
      ) {
        this.$message.error("男发音和女发音必须上传一个");
        return;
      } else if (this.addSanctumTalkData.portrait == "") {
        this.$message.error("请上传肖像缩略图");
        return;
      }
      SanctumTalkAdd(this.addSanctumTalkData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("添加对话成功");
        this.addSanctumTalkDialog = false;
        this.getSanctumTalkList(this.sanctumTalkId);
      });
    },

    // 点击修改圣地巡礼对话
    editSanctumTalk(item) {
      this.addSanctumTalkTitle = "编辑日语APP圣地巡礼对话";
      this.isAddSanctumTalk = "edit";
      this.addSanctumTalkDialog = true;
      this.addSanctumTalkData = JSON.parse(JSON.stringify(item));
      this.portraitThumbSrc = this.addSanctumTalkData.portrait_url;
    },

    // 点击立即修改圣地巡礼对话
    editSanctumTalkNow() {
      this.addSanctumTalkData.place_id = this.sanctumTalkId;
      if (this.addSanctumTalkData.role == "") {
        this.$message.error("请选择角色位置");
        return;
      } else if (this.addSanctumTalkData.name == "") {
        this.$message.error("请输入角色名字");
        return;
      } else if (this.addSanctumTalkData.original == "") {
        this.$message.error("请输入原文");
        return;
      } else if (this.addSanctumTalkData.chinese == "") {
        this.$message.error("请输入翻译");
        return;
      } else if (
        this.addSanctumTalkData.male_voice == "" &&
        this.addSanctumTalkData.female_voice == ""
      ) {
        this.$message.error("男发音和女发音必须上传一个");
        return;
      } else if (this.addSanctumTalkData.portrait == "") {
        this.$message.error("请上传肖像缩略图");
        return;
      }
      SanctumTalkEdit(this.addSanctumTalkData).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("修改对话成功");
        this.addSanctumTalkDialog = false;
        this.getSanctumTalkList(this.sanctumTalkId);
      });
    },

    // 点击删除修改圣地巡礼对话
    async removeSanctumTalk(id) {
      const result = await this.$confirm("是否要删除该对话?", "删除提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      }).catch((err) => err);
      if (result !== "confirm") return this.$message.info("已经取消删除");
      SanctumTalkDel({ id: id }).then((res) => {
        if (res.code !== 1) {
          this.$message.error(res.msg);
          return;
        }
        this.$message.success("删除知识点成功");
        this.getSanctumTalkList(this.sanctumTalkId);
      });
    },

    // 添加修改圣地巡礼对话弹框关闭事件
    addSanctumTalkClose() {
      this.addSanctumTalkData = {
        place_id: "",
        role: "",
        name: "",
        original: "",
        chinese: "",
        male_voice: "",
        female_voice: "",
        portrait: ""
      };
      this.male_voiceNmme = "";
      this.female_voiceName = "";
      this.male_voiceProgress = 0;
      this.female_voiceProgress = 0;
      this.portraitThumbSrc = "";
    },

    //  查询
    toQuery() {
      this.queryForm.page = 1;
      if (this.times) {
        this.queryForm.start_time = new Date(this.times[0]).getTime() / 1000;
        this.queryForm.end_time = new Date(this.times[1]).getTime() / 1000;
      } else if (this.times == null) {
        this.queryForm.start_time = null;
        this.queryForm.end_time = null;
      }
      this.getSanctumPlaceList();
    },
    handleSizeChange(size) {
      this.queryForm.limit = size;
      this.getSanctumPlaceList();
    },
    handleCurrentChange(page) {
      this.queryForm.page = page;
      this.getSanctumPlaceList();
    },
  },
};
</script>

<style scoped lang="less">
.generate {
  float: right;
}
.el-tag {
  cursor: pointer;
}
::v-deep .ql-editor {
  min-height: 400px;
}
.thumb_box {
  width: 200px;
  height: 120px;
  img {
    width: 100%;
    height: 100%;
  }
}

.video_box {
  width: 100%;
  height: 300px;
  margin-bottom: 10px;
  video {
    width: 100%;
    height: 100%;
  }
}
.title_box {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 30px;
}
.addtopic {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}
.add_box {
  margin-bottom: 20px;
}

.thumb_box {
  width: 100%;
  height: 100%;

  img {
    width: 200px;
    height: 120px;
  }
}
.tips {
  color: red;
}
</style>
