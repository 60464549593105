import request from "@/utils/request";

// 日语APP圣地巡礼区域列表
export function SanctumPlaceList(params) {
    return request({
        url: '/admin/japanese_v3/sanctumPlaceList',
        method: 'get',
        params
    })
}

// 添加日语APP圣地巡礼区域
export function SanctumPlaceAdd(data) {
    return request({
        url: '/admin/japanese_v3/sanctumPlaceAdd',
        method: 'post',
        data
    })
}

// 编辑日语APP圣地巡礼区域
export function SanctumPlaceEdit(data) {
    return request({
        url: '/admin/japanese_v3/sanctumPlaceEdit',
        method: 'put',
        data
    })
}

// 删除日语APP圣地巡礼区域
export function SanctumPlaceDel(data) {
    return request({
        url: '/admin/japanese_v3/sanctumPlaceDel',
        method: 'delete',
        data
    })
}


// 圣地巡礼上传COS签名
export function getSanctumCosToken(params) {
    return request({
        url: '/admin/japanese_v3/getSanctumCosToken',
        method: 'get',
        params
    })
}

// 日语APP圣地巡礼区域列表
export function SanctumList(params) {
    return request({
        url: '/admin/japanese_v3/sanctumList',
        method: 'get',
        params
    })
}


// 编辑日语APP圣地巡礼区域
export function SanctumEdit(data) {
    return request({
        url: '/admin/japanese_v3/sanctumEdit',
        method: 'put',
        data
    })
}


// 日语APP圣地巡礼知识点列表
export function SanctumLoreList(params) {
    return request({
        url: '/admin/japanese_v3/sanctumLoreList',
        method: 'get',
        params
    })
}

// 添加日语APP圣地巡礼知识点
export function SanctumLoreAdd(data) {
    return request({
        url: '/admin/japanese_v3/sanctumLoreAdd',
        method: 'post',
        data
    })
}

// 编辑日语APP圣地巡礼知识点
export function SanctumLoreEdit(data) {
    return request({
        url: '/admin/japanese_v3/sanctumLoreEdit',
        method: 'put',
        data
    })
}

// 删除日语APP圣地巡知识点
export function SanctumLoreDel(data) {
    return request({
        url: '/admin/japanese_v3/sanctumLoreDel',
        method: 'delete',
        data
    })
}


// 日语APP圣地巡礼对话列表
export function SanctumTalkList(params) {
    return request({
        url: '/admin/japanese_v3/sanctumTalkList',
        method: 'get',
        params
    })
}

// 添加日语APP圣地巡礼对话
export function SanctumTalkAdd(data) {
    return request({
        url: '/admin/japanese_v3/sanctumTalkAdd',
        method: 'post',
        data
    })
}

// 编辑日语APP圣地巡礼对话
export function SanctumTalkEdit(data) {
    return request({
        url: '/admin/japanese_v3/sanctumTalkEdit',
        method: 'put',
        data
    })
}

// 删除日语APP圣地巡对话
export function SanctumTalkDel(data) {
    return request({
        url: '/admin/japanese_v3/sanctumTalkDel',
        method: 'delete',
        data
    })
}
